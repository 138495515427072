\<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            label="분류코드"
            name="pipeTypeCd"
            v-model="searchParam.pipeTypeCd">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-text
            label="유체의명칭또는구분"
            name="pipeName"
            v-model="searchParam.pipeName">
          </c-text>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-process
            label="관련공정"
            multiple="single"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-dept
            type="search"
            label="관리부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="pipegasket"
      title="배관 및 개스킷 목록"
      tableId="pipegasket"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="psiPipeGasketId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn
            v-if="editable"
            label="엑셀업로드"
            icon="upload"
            @btnClicked="excelUploadData" />
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="pipegasketPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'piping-gaskets',
  data() {
    return {
      grid: {
        merge: [
          { index: 0, colName: 'pipeTypeCd' },
          { index: 1, colName: 'pipeTypeCd' },
          // { index: 2, colName: 'pipeTypeCd' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          // {
          //   name: 'revisionNum',
          //   field: 'revisionNum',
          //   label: 'Rev.',
          //   align: 'center',
          //   style: 'width:50px',
          //   sortable: true,
          // },
          {
            name: 'pipeTypeCd',
            field: 'pipeTypeCd',
            label: '분류코드',
            align: 'center',
            type: 'link',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'pipeName',
            field: 'pipeName',
            label: '유체의명칭 또는 구분',
            style: 'width:200px',
            align: 'left',
            sortable: true,
          },
          {
            name: 'designPress',
            field: 'designPress',
            label: '설계압력(MPa)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'designTemp',
            field: 'designTemp',
            label: '설계온도(℃)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'pipeMaterial',
            field: 'pipeMaterial',
            label: '배관재질',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'gasketMaterial',
            field: 'gasketMaterial',
            label: '개스킷 재질 및 형태',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'noneDestructPercent',
            field: 'noneDestructPercent',
            label: '비파괴검사율(%)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'afterHeatTreatFlag',
            field: 'afterHeatTreatFlag',
            label: '후열처리여부',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'remarks',
            field: 'remarks',
            label: '비고',
            align: 'left',
            style: 'width:180px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        deviceName: '',
        pipeTypeCd: '',
        pipeName: '',
        processCd: '',
        deptCd: '',
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.psi.pfi.pipegasket.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.pipegasketPopup(row);
    },
    pipegasketPopup(result) {
      this.popupOptions.target = () => import(`${"./pipingGasketsDetail.vue"}`);
      this.popupOptions.title = '배관 및 개스킷 상세'; 
      this.popupOptions.param = {
        psiPipeGasketId: result ? result.psiPipeGasketId : '',
      };
      this.popupOptions.width = '90%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    excelUploadData() {
      this.popupOptions.title = '배관 및 개스킷 업로드';
      this.popupOptions.target = () => import(`${'./pipingGasketsExcelUpload.vue'}`);
      this.popupOptions.isFull = true;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeExcelUploadPopup;
    },
    closeExcelUploadPopup(_result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (_result && _result.length > 0) {
        let s_data = this.$_.filter(_result, (item) => { return !item.error_message })
        this.$_.forEach(s_data, item => {
          // item.plantCd = this.searchParam.plantCd;
          item.SysRevision = 1;
          item.regUserId = this.$store.getters.user.userId;  // 등록자 ID
          item.chgUserId = this.$store.getters.user.userId;  // 수정자 ID
        })

        this.$http.url = transactionConfig.psi.pfi.pipegasket.insert.url + '/excel';
        this.$http.type = 'POST';
        this.$http.param = s_data;
        this.$http.request(() => {
          window.getApp.$emit('APP_REQUEST_SUCCESS');
          this.getList();
        });
      }
    },
  }
};
</script>
